import React, { useEffect, useState,useRef } from 'react'
import { connect } from "react-redux";
import { Accordion, Icon } from 'semantic-ui-react';
import {
    setPageTitle,
    toggleNavigation,
    setBreadCrum,
    setMessage,
    toggleBreadcrum

} from "../../../../store/Page";
import IntegrationAccordian from './IntegrationAccordian';
import './Integration.css';
import Facebook from '../../../Integration/Facebook';
import SalesForce from "../../../Integration/Salesforce";
import Webhook from "../../../Integration/Webhook";
import Keep from "../../../Integration/Keep";
import MarketSharp from "../../../Integration/MarketSharp";
import Zapier from "../../../Integration/Zapier";
import Instructions from "../../../Integration/Instructions";
import Dialogflow from '../../../Integration/Dialogflow';
import CustomIntegration from  '../../../Integration/CustomIntegration';
import ActCrm from "../../../Integration/ActCrm";
import '../../../Integration/ActCrm.css';
import ServiceTitanForChat from "../../../Integration/ServiceTitanChat";
import ServiceTitanForEngage from "../../../Integration/ServiceTitanEngage";


const Integration = (props) => {

    const [activeMenu, setActiveMenu] = useState('custom_integration');
    const [activeMenuId, setactiveMenuId] = useState('custom_integration');

    //State for Service Titan --> lifted up State from service titan components
    const [chatOnValue,setChatOnValue] = useState("0")
    const [engageOnValue,setEngageOnValue] = useState("0")
    const [totalCount,setTotalCount] = useState(0)
    const [data,setData] = useState({
        clientId:"",
        clientSecret:"",
        appKey:"",
        tenetId:"",
        webOnOff:"OFF",
        voiceOnOff:"OFF",
        createUserOnOff:"OFF",
        id:0  
    })
    const [dataEngage,setDataEngage] = useState({
        clientId:"",
        clientSecret:"",
        appKey:"",
        tenetId:"",
        webOnOff:"OFF",
        voiceOnOff:"OFF",
        createUserOnOff:"OFF",
        id:0  
    })
    //End of Service Titan State

    useEffect(() => {
        console.log("api in", props);
        const {
            setPageTitle,
            toggleNavigation,
            location,
            PageMessage,
            setMessage,
            setBreadCrum,
            toggleBreadcrum
        } = props;
    
        setPageTitle({ title: "Settings", subHeaderTitle: "Settings" });
        setBreadCrum({ breadcrum: null });

        toggleNavigation({ toggle: false });
        toggleBreadcrum({ toggle: false });
        return () => { }
    }, []);

    // handle leftbar click
    const selectLeftMenu = (selectedMenu, selectMenuId) => {
        setActiveMenu(selectedMenu);
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content kt-grid__item kt-grid__item--fluid">
                <div className="yr-body-p">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="kt-portlet shadow-none">
                                <div className="kt-portlet__body yr-pd0-lr yr-mt-20 yr-pd0-mob">
                                    <div className="row">
                                        <div className="col-lg-4 yr-pd0-mob yr-pd0-lr">
                                            <div className="box-title mx-lg-3">
                                                Integrations
                                            </div>
                                            <div className="yr-chat-round-box pb-5 mg-l-0 mx-lg-4">
                                                <IntegrationAccordian
                                                    selectLeftMenu={selectLeftMenu}
                                                    activeMenu={activeMenu}
                                                    user={props.user}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="box-title mx-lg-3">
                                                Let's get you up and running
                                            </div>
                                            
                                            
                                            <div className="tab-content">
                                            <div className={`tab-pane active`} id="custom_integration">
                                                    <div className="yr-chat-round-box">
                                                        <CustomIntegration />
                                                    </div>
                                                </div>
                                           
                                                <div className={`tab-pane`} id="dialog">
                                                    <div className="yr-chat-round-box">
                                                        <Dialogflow />
                                                    </div>
                                                </div>
                                               
                                                <div className="tab-pane" id="b">
                                                    <div className="yr-chat-round-box" style={{padding: 26}}>
                                                        <Facebook user={props.user} />
                                                    </div>
                                                </div>      
                                                                                          <div className="tab-pane" id="d">
                                                    <div className="yr-chat-round-box">
                                                        <SalesForce />
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="e">
                                                    <div className="yr-chat-round-box">
                                                        <Keep />
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane"
                                                    id="marketsharp-integration"
                                                >
                                                    <div className="yr-chat-round-box">
                                                        <MarketSharp />
                                                    </div>
                                                </div>

                                                <div className="tab-pane" id="f">
                                                    <div className="yr-chat-round-box">
                                                        <Zapier />
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="actcrm">
                                                    <div className="yr-chat-round-box">
                                                        <ActCrm />
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="servicetitanconchat">
                                                    <div className="yr-chat-round-box">
                                                        <ServiceTitanForChat 
                                                        chatOnValue = {chatOnValue}
                                                        engageOnValue={engageOnValue}
                                                        setChatOnValue={setChatOnValue}
                                                        setEngageOnValue={setEngageOnValue}
                                                        setData={setData}
                                                        dataEngage={dataEngage}
                                                        setDataEngage={setDataEngage}
                                                        data={data}
                                                        setTotalCount={setTotalCount}
                                                        totalCount={totalCount}
                                                        user={props.user}
                                                        activeMenu={activeMenu}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="servicetitanconengage">
                                                    <div className="yr-chat-round-box">
                                                        <ServiceTitanForEngage 
                                                        chatOnValue = {chatOnValue}
                                                        engageOnValue={engageOnValue}
                                                        setChatOnValue={setChatOnValue}
                                                        setEngageOnValue={setEngageOnValue}
                                                        setDataEngage={setDataEngage}
                                                        data={data}
                                                        dataEngage={dataEngage}
                                                        setData={setData}
                                                        setTotalCount={setTotalCount}
                                                        totalCount={totalCount}
                                                        activeMenu={activeMenu}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="g">
                                                    <div className="yr-chat-round-box">
                                                        <Webhook />
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="h">
                                                    <div className="yr-chat-round-box">
                                                        Google Analytics / Tag manager Setup
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        PageMessage: state.page.message,
        user: state.user.company,
    };
};
export default connect(mapStateToProps, {
    setPageTitle,
    toggleNavigation,
    setBreadCrum,
    setMessage,
    toggleBreadcrum
})(Integration);