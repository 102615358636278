import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react';
import Facebook from '../../../Integration/Facebook';

const IntegrationAccordian = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleAccordianClick = (e) => {
        const index = e;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    }
    return (
        <Accordion>
            <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={() => handleAccordianClick(0)}
            >
                <Icon name='angle right' />
                Voice/Chat Integrations
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
                <div className='integration__accordian-content'>
                    <div className="yr-chat-round-box pb-5 mg-l-0 mx-lg-4">
                        <ul className="nav nav-tabs tabs-left-nav">
                          <li
                                className={props.activeMenu === "custom_integration" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('custom_integration', 'custom_integration')}
                            >
                                <a href="#custom_integration" data-toggle="tab" className={props.activeMenu === "custom_integration" ? "active" : ""}>
                                    <span className="yr-icon-customintegration icon-mr" />
                                   Custom Integration
                                </a>
                            </li>
                            <li
                                className={props.activeMenu === "dialogflow" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('dialogflow', 'dialog')}
                            >
                                <a href="#dialog" data-toggle="tab" className={props.activeMenu === "dialogflow" ? "active" : ""}>
                                    <span className="yr-icon-dialogflow icon-mr" />
                                    Google My Business
                                </a>
                            </li>
                            <li
                                className={props.activeMenu === "facebook" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('facebook', 'b')}
                            >
                                <a href="#b" data-toggle="tab" className={props.activeMenu === "facebook" ? "active" : ""}>
                                    <span className="yr-icon-facebook icon-mr" />
                                    Facebook Messenger Integration
                                </a>
                            </li>
                            <li
                                className={props.activeMenu === "salesforce" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('salesforce', 'd')}
                            >
                                <a href="#d" data-toggle="tab" className={props.activeMenu === "salesforce" ? "active" : ""}>
                                    <span className="yr-icon-sales-force icon-mr" />
                                    Salesforce Integration
                                </a>
                            </li>
                            <li
                                className={props.activeMenu === "servicetitanForChat" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('servicetitanForChat', 'servicetitanconchat')}
                            >
                                <a href="#servicetitanconchat" data-toggle="tab" className={props.activeMenu === "servicetitanForChat" ? "active" : ""}>
                                    <span className="yr-icon-servicetitan icon-mr" />
                                    ServiceTitan Integration
                                </a>
                            </li>
                            <li
                                className={props.activeMenu === "actcrm" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('actcrm', 'actcrm')}
                            >
                                <a href="#actcrm" data-toggle="tab" className={props.activeMenu === "actcrm" ? "active" : ""}>
                                    <span className="yr-icon-actcrm icon-mr" />
                                    Act! Integration
                                </a>
                            </li>
                            <li
                                className={props.activeMenu === "keap" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('keap', 'e')}
                            >
                                <a href="#e" data-toggle="tab" className={props.activeMenu === "keap" ? "active" : ""}>
                                    <span className="yr-icon-keap icon-mr" />
                                    Keap Integration
                                </a>
                            </li>
                            <li
                                className={props.activeMenu === "marketsharp" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('marketsharp', 'marketsharp-integration')}
                            >
                                <a
                                    href="#marketsharp-integration"
                                    data-toggle="tab"
                                    className={props.activeMenu === "marketsharp" ? "active" : ""}
                                >
                                    <span class="yr-icon-marketsharp icon-mr"></span>
                                    Marketsharp Integration
                                </a>
                            </li>
                            <li
                                className={props.activeMenu === "zapier" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('zapier', 'f')}
                            >
                                <a href="#f" data-toggle="tab" className={props.activeMenu === "zapier" ? "active" : ""}>
                                    <span className="yr-icon-zapier icon-mr" />
                                    Zapier Integration
                                </a>
                            </li>
                            <li
                                className={props.activeMenu === "webhook" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('webhook', 'g')}
                            >
                                <a href="#g" data-toggle="tab" className={props.activeMenu === "webhook" ? "active" : ""}>
                                    <span className="yr-icon-webhook icon-mr" />
                                    Webhook Integration
                                </a>
                            </li>
                            {/* <li
                                className={props.activeMenu === "dialogflow" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('dialogflow', 'dialog')}
                            >
                                <a href="#dialog" data-toggle="tab" className={props.activeMenu === "dialogflow" ? "active" : ""}>
                                    <span className="yr-icon-dialogflow icon-mr" />
                                    Dialogflow Integration
                                </a>
                            </li> */}
                            <li
                                className={props.activeMenu === "googleAnalytic" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('googleAnalytic', 'h')}
                            >
                                <a href="#h" data-toggle="tab" className={props.activeMenu === "googleAnalytic" ? "active" : ""}>
                                    <span className="yr-icon-tag-m-ga icon-mr" />
                                    Google Analytics / Tag manager Setup
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Accordion.Content>
            <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={() => handleAccordianClick(1)}
            >
                <Icon name='angle right' />
                Review/Engage Integrations
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
                <div className=''>
                    <div className="yr-chat-round-box pb-5 mg-l-0 mx-lg-4">
                        <ul className="nav nav-tabs tabs-left-nav">
                            <li
                                className={props.activeMenu === "servicetitanForEngage" ? "active" : ""}
                                onClick={() => props.selectLeftMenu('servicetitanForEngage', 'servicetitanconengage')}
                            >
                                <a href="#servicetitanconengage" data-toggle="tab" className={props.activeMenu === "servicetitanForEngage" ? "active" : ""}>
                                    <span className="yr-icon-servicetitan icon-mr" />
                                    ServiceTitan Integration
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Accordion.Content>
        </Accordion>
    )
}

export default IntegrationAccordian