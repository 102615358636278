import axios from "../config/axios";
import config from "../config/index";
const botbuilder = {

    getLists: (params) =>
        axios
            .post(`/botbuilder/Botbuilder/getlist`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    deleteworkspace: (params) =>
        axios
            .post(`/botbuilder/Botbuilder/deleteworkspace`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    updateWorkspace: (params) =>
        axios
            .post(`/botbuilder/Botbuilder/updateWorkspace`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    updateWorkspaceName: (params) =>
        axios
            .post(`/botbuilder/Botbuilder/updateworkspacename`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    deleteWorkspace: (params) =>
        axios
            .post(`/botbuilder/Botbuilder/delketeworkspace`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    duplicateWorkspace: (params) =>
        axios
            .post(`/botbuilder/Botbuilder/duplicateworkspace`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    getSearchList: (params) =>
        axios
            .post(`/botbuilder/Botbuilder/getsearchlist`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    viewwokspacedetail: (params) =>
        axios
            .post(`/botbuilder/Botbuilder/viewwokspacedetail`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    createworkspace: (params) =>
        axios
            .post(`/botbuilder/Botbuilder/createworkspace`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    updateposition: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/updateposition`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    copybranch: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/copybranch`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    targetedbranchdetail: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/targetedbranchdetail`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    deletebranch: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/deletebranch`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    deployworkspace: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/deployworkspace`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    responsetime: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/responsetime`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveBranch: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/saveBranch`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    editBranch: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/editBranch`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveAIRules: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/saveAIRules`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    deleteAIRules: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/deleteAIRules`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    updateAIRules: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/updateAIRules`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    capturedsave: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/capturedsave`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    captureddelete: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/captureddelete`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    createBranch: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/createBranch`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    addNewBranch: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/addNewBranch`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    finalSave: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/finalSave`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveQuestionResponse: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/saveQuestionResponse`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    
    saveZapWebhooks: (params) =>
    axios
        .post(`/botbuilder/BotBuilderDetail/saveZapWebhooks`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),        

    saveZapWebhooks: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/saveZapWebhooks`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    deleteQuestionResponse: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/deleteQuestionResponse`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    deletewebhook: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/deletewebhook`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    editQuestionResponse: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/editQuestionResponse`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveMultipleChoice: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/saveMultipleChoice`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


    deleteMultipleChoiceQuestionAll: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/deleteMultipleChoiceQuestionAll`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveBranchLink: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/saveBranchLink`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    getLinkDetails: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/getLinkDetails`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    deleteBranchLink: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/deleteBranchLink`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),



    updateBranchTeamNotifyMembers: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/updateBranchTeamNotifyMembers`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    getLiveChatRules: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/getLiveChatRules`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveTimeZoneDataBranch: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/saveTimeZoneDataBranch`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    resetForm: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/resetForm`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    editBranchEmail: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/editBranchEmail`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    deleteBranchEmail: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/deleteBranchEmail`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    getTestingView: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/getTestingView`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

   getWatsonData: (params) =>
    axios
        .post(`/botbuilder/BotBuilderDetail/getWatsonData`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

   addSuggestedInetentExample: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/addSuggestedInetentExample`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

   getZipCodeRules: (params) =>
            axios
                .post(`/botbuilder/BotBuilderDetail/getZipCodeRules`, params)
                .then((resp) => resp.data)
                .catch((err) => console.log(err)),

    saveZipCodeRules: (params) =>
    axios
        .post(`/botbuilder/BotBuilderDetail/saveZipCodeRules`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

        updateBranchforAll: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/updateBranchforAll`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),    
        
        deleteZipCodeRules: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/deleteZipCodeRules`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),  


        acceptAttchmentResponse: (params) =>
        axios
            .post(`/botbuilder/BotBuilderDetail/acceptAttchmentResponse`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),  

    getGtmTrigger: (params) =>
    axios
        .post(`/botbuilder/BotBuilderDetail/getGtmTrigger`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
            
    saveGtmTrigger: (params) =>
    axios
        .post(`/botbuilder/BotBuilderDetail/saveGtmTrigger`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),    

    saveZipCodeRulesBulk: (params) =>
    axios
        .post(`/botbuilder/BotBuilderDetail/saveZipCodeRulesBulk`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),    

    deletebulkzip: (params) =>
    axios
        .post(`/botbuilder/BotBuilderDetail/deletebulkzip`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)), 
                
    fetchTrainYourAiData: (param) => 
    axios
            .post('botbuilder/BotBuilderDetail/getTranYourAi', param)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
                
    saveTrainYourAiData: (param) => 
    axios
            .post('botbuilder/BotBuilderDetail/saveTranYourAi', param)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

    savechatReport: (params) =>
    axios
        .post(`/botbuilder/BotBuilderDetail/saveChatReport`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)), 
        
    createChatGptIntegration: (params) =>
    axios
        .post(`/botbuilder/GptChatThirdPartyIntegration/createChatGptIntegration`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),  
    createChatGptIntegrationValue: (params) =>
    axios
        .post(`/botbuilder/GptChatThirdPartyIntegration/createChatGptZpSTIntegrationValue`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)), 
        
        getintegrationlist: (params) =>
    axios
        .post(`/botbuilder/GptChatThirdPartyIntegration/getintegrationlist`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)), 

        deleteCustomSearchFormIntegration: (params) =>
        axios
            .post(`/botbuilder/GptChatThirdPartyIntegration/deleteCustomSearchFormIntegration`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),  
    changeContextBranchName: (param) =>
        axios
            .post('botbuilder/Botbuilder/changeContext', param)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),


        servieTitanchatBranchList: (params) =>
        axios
            .post("/botbuilder/Botbuilder/activeBotData", params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    servieTitanchatTurboBranchList: (params) =>
            axios
                .post("/botbuilder/Botbuilder/activeBotData", params)
                .then((resp) => resp.data)
                .catch((err) => console.log(err)),        

    saveStBranchName: (params) =>
        axios
            .post("/botbuilder/Botbuilder/saveStBranch", params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    serviceTitanAddedBranchList: (params) =>
        axios
            .post("/botbuilder/Botbuilder/StBranchesList", params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    serviceTitanWebDeleteBranch: (params) =>
        axios
            .post("/botbuilder/Botbuilder/deleteSTBranch", params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    saveCallFarwordNumber: (params) =>
        axios
            .post("/botbuilder/Botbuilder/saveCallFarwordNumber", params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
            
    deleteCallFarwordNumber: (params) =>
        axios
            .post("/botbuilder/Botbuilder/deleteCallFarwordNumber", params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    gettimezoneHourConfigration: () =>
        axios
            .get("/botbuilder/Botbuilder/getTimezoneHourLilst")
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    savetimezoneHourConfigration : (params) => 
        axios 
            .post("/botbuilder/Botbuilder/saveTimezoneHourLilst",params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
            
    fetchTimezoneHourLilst : (params) => 
        axios 
            .post("/botbuilder/Botbuilder/fetchTimezoneHourLilst",params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    getOccupiedHours : (params) => 
        axios 
            .post("/botbuilder/Botbuilder/getOccupiedHours",params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),

    getdefaultPauses: (params) =>
    axios
        .get(`${config.apiBaseURL2}admin-pauses`, {})
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

        getvoicePauses: (params) =>
    axios
        .get(`${config.apiBaseURL2}getvoicePauses?`+ params, {})
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

        savevoicePauses: (params) =>
    axios
        .post(`${config.apiBaseURL2}savepausesSound`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
        
        deletePause: (params) =>
    axios
        .post(`${config.apiBaseURL2}deletePause`, params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)), 

        deletedefaultPause: (params) =>
        axios
            .post(`${config.apiBaseURL2}deletedefaultPause`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),     

        addpauseText: (params) =>
        axios
            .post(`${config.apiBaseURL2}addpauseText`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),  

        saveParseDaataConfigration: (params) => axios
        .post('/botbuilder/BotBuilderDetail/saveParseDataConfigration', params).then((resp) => resp.data)
        .catch((err) => console.log(err)), 

        importParseDaataConfigration: (params) => axios
        .post('/botbuilder/BotBuilderDetail/importParseDataConfigration', params).then((resp) => resp.data)
        .catch((err) => console.log(err)), 

        getParseDaataConfigration: (params) => axios
        .post('/botbuilder/BotBuilderDetail/fetchturboParsingConfigration', params).then((resp) => resp.data)
        .catch((err) => console.log(err)),
         
    getVoiceWorkspaceBranch : (formData) =>       
            axios.post("/settings/voiceNotification/getVoiceWorkspaceBranch", formData),

    getHolidaysList : (params) => 
        axios 
        .post("/botbuilder/Botbuilder/getHolidaysList",params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),

        deleteHolidayNode : (paraholidayparams) => 
            axios 
            .post("/botbuilder/Botbuilder/deleteHolidayNode",paraholidayparams)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    };
    


export default botbuilder;
